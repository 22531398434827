export const TimeRangeKey = {
  full: '&timerange=full',
  list: '&timerange=list',
  short: '&timerange=short',
  empty: '&timerange',
  null: '&timerange=null',
  timeline: '&timerange=timeline',
  wfmdefault: '&timerange=wfm',
  wfmforecast: '&timerange=wfmforecast',
};
