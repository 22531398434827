import { TimeOption } from '@grafana/data';

//Historical options
export const historicalOptions: TimeOption[] = [
  { from: 'now/d', to: 'now', display: 'Today so far' },
  { from: 'now/w', to: 'now', display: 'This week so far' },
  { from: 'now/M', to: 'now', display: 'This month so far' },
];

//Kiosk mode time range
export const shortTimeModeOptions: TimeOption[] = [
  { from: 'now-24h', to: 'now', display: 'Last 24 hours' },
  { from: 'now-2d', to: 'now', display: 'Last 2 days' },
  { from: 'now-7d', to: 'now', display: 'Last 7 days' },
  { from: 'now-30d', to: 'now', display: 'Last 30 days' },
  { from: 'now-90d', to: 'now', display: 'Last 90 days' },
];

//WFM default time range
export const wfmDefaultTimeModeOptions: TimeOption[] = [
  { from: 'now/d', to: 'now/d', display: 'Today' },
  { from: 'now/w', to: 'now/w', display: 'Week' },
  { from: 'now/M', to: 'now/M', display: 'Month' },
];

//WFM forecast time range
export const wfmForecastTimeModeOptions: TimeOption[] = [
  { from: 'now/d+1d', to: 'now/d+1d', display: 'Tomorrow' },
  { from: 'now/w+1w', to: 'now/w+1w', display: 'Next week' },
  { from: 'now/M+1M', to: 'now/M+1M', display: 'Next month' },
];

// Can add more option here when create new time range option
