export const TimeRangeKey = {
  full: '&timerange=full',
  list: '&timerange=list',
  short: '&timerange=short',
  empty: '&timerange',
  null: '&timerange=null',
  timeline: '&timerange=timeline',
  wfmdefault: '&timerange=wfm',
  wfmforecast: '&timerange=wfmforecast',
};

export const IsContainTimeRange = () => {
  let keyList = [
    TimeRangeKey.full,
    TimeRangeKey.list,
    TimeRangeKey.short,
    TimeRangeKey.timeline,
    TimeRangeKey.wfmdefault,
    TimeRangeKey.wfmforecast,
  ];
  const currentUrl = window.location.href;
  let result = false;
  keyList.forEach((element) => {
    if (currentUrl.includes(element)) {
      result = true;
    }
  });
  return result;
};
